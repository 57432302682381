import "./Catalog.css";

import {
  useLocation,
  useNavigate,
  Navigate,
  Link,
  useParams,
} from "react-router-dom";

import { useRef, useState, useEffect, useCallback } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Storage } from "../Firebase/firebase";
import { ToastContainer, toast } from "react-toastify";
import { clubOffer, userService } from "../../_services";
import { useTranslation } from "react-i18next";
import OfferCard from "./OfferCard";

export default function Catalog() {
  const params = useParams();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const [data, dataSet] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [dataOffer, setDataOffer] = useState([]);

  const fetchMyAPI = useCallback(async () => {
    setIsLoading(true);
    const res = await userService.getClubDetails(params.id);
    dataSet(res);
    console.log(res);
    const resOffer = await userService.getClubOffer(params.id);
    console.log(resOffer);
    setDataOffer(resOffer);
    setIsLoading(false);
  }, [params.id]); // if userId changes, useEffect will run again

  useEffect(() => {
    fetchMyAPI();
  }, []);

  const changeLanguageEn = () => {
    i18n.changeLanguage("en");
  };
  const changeLanguageHe = () => {
    i18n.changeLanguage("he");
    console.log(i18n.language);
  };

  return (
    <div
      dir="rtl"
      className=" w-[calc(100vw - 100%)] h-[100vh] bg-[#00b9bf] gradientBackground overflow-y-auto"
    >
      {/* <h1>This is added from satish {params.id}</h1>
      <div className="textHeader">
        <div
          onClick={() => {
            changeLanguageHe();
          }}
        >
          <span>חבר מועדון</span>
        </div>
      </div> */}

      {/* <Link to="/page2">{t("page2")}</Link> */}
      <div className="body">
        <div className="header">
          <div className="textHeader">
            <p>{data.name}</p>
          </div>
          <div className="logoHolder">
            <div>
              <img src={data?.smallLogoUrl} alt="" />
            </div>
          </div>

          {/* <img className="chevronLeft" src="/images/chevron-left.svg" alt="" /> */}
        </div>
        {isLoading ? (
          <div className="loaderBox">
            <svg viewBox="25 25 50 50">
              <circle r="20" cy="50" cx="50"></circle>
            </svg>
          </div>
        ) : (
          <div className="items">
            {dataOffer &&
              dataOffer.map((e, index) => {
                return <OfferCard data={e} index={index}  club={data}/>;
              })}
          </div>
        )}

        {/* <div className="items">
          <p style={{ marginBottom: "10px" }}>סך הכל הטבות 9</p>
          <div className="item">
            <div className="imageHolder">
              <img
                src="https://img.freepik.com/premium-photo/chicken-pork-beef-meat-skewers-with-vegetables-spicy-potatoes_52137-30827.jpg"
                alt=""
              />{" "}
            </div>
            <div className="contentItem">
              <div className="contentItemIcons">
                <img src="/images/material-share.svg" alt="" />
              </div>
              <div className="itemTitle">
                <p>שובר מתנה סופר-פארם בשווי 130</p>
                <div>
                  <span style={{ fontSize: "28px", color: "#4D4D4D" }}>
                    מחיר
                  </span>
                  <span className="price">₪100</span>
                </div>
                <div className="holes">
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <img
                    className="gift-box"
                    src="/images/gift-box.png"
                    alt="gift-box.png"
                  />
                </div>
              </div>
              <div className="footerItem">
                <div>
                  <p>הגבלת רכישה - ללא הגבלה</p>
                  <span>לשימוש עד</span>
                  <span>15.12.2020</span>
                </div>
                <div className="button-holder">
                  <div className="item-logo">
                    <img src="/images/logo.svg" alt="" />
                  </div>
                  <button>לקנייה באפליקציה</button>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="imageHolder">
              <img
                src="https://img.freepik.com/premium-photo/chicken-pork-beef-meat-skewers-with-vegetables-spicy-potatoes_52137-30827.jpg"
                alt=""
              />
            </div>
            <div className="contentItem">
              <div className="contentItemIcons">
                <img src="/images/material-share.svg" alt="" />
              </div>
              <div className="itemTitle">
                <p>שובר מתנה סופר-פארם בשווי 130</p>
                <div>
                  <span style={{ fontSize: "28px", color: "#4D4D4D" }}>
                    מחיר
                  </span>
                  <span className="price">₪100</span>
                </div>
                <div className="holes">
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <img
                    className="gift-box"
                    src="/images/gift-box.png"
                    alt="gift-box.png"
                  />
                </div>
              </div>
              <div className="footerItem">
                <div>
                  <p>הגבלת רכישה - ללא הגבלה</p>
                  <span>לשימוש עד</span>
                  <span>15.12.2020</span>
                </div>
                <div className="button-holder">
                  <div className="item-logo">
                    <img src="/images/logo.svg" alt="" />
                  </div>
                  <button>לקנייה באפליקציה</button>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="imageHolder">
              <img
                src="https://img.freepik.com/premium-photo/chicken-pork-beef-meat-skewers-with-vegetables-spicy-potatoes_52137-30827.jpg"
                alt=""
              />
            </div>
            <div className="contentItem">
              <div className="contentItemIcons">
                <img src="/images/material-share.svg" alt="" />
              </div>
              <div className="itemTitle">
                <p>שובר מתנה סופר-פארם בשווי 130</p>
                <div>
                  <span style={{ fontSize: "28px", color: "#4D4D4D" }}>
                    מחיר
                  </span>
                  <span className="price">₪100</span>
                </div>
                <div className="holes">
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <span className="hole"></span>
                  <img
                    className="gift-box"
                    src="/images/gift-box.png"
                    alt="gift-box.png"
                  />
                </div>
              </div>
              <div className="footerItem">
                <div className="date">
                  <p>הגבלת רכישה - ללא הגבלה</p>
                  <span>לשימוש עד</span>
                  <span>15.12.2020</span>
                </div>
                <div className="button-holder">
                  <div className="item-logo">
                    <img src="/images/logo.svg" alt="" />
                  </div>
                  <button>לקנייה באפליקציה</button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
