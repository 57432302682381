import { useEffect, useRef, useState } from "react";
import { userService } from "../../_services";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import EnterCodeModal from "../Model/EnterCodeModal";
import QrCodeModal from "../Model/QrCodeModel";

export default function Main() {
  const { t } = useTranslation();
  const [codeRes, setCodeRes] = useState();
  const [code, setCode] = useState("");
  const inputRef = useRef();

  const [showMainModal, setShowMainModal] = useState(false);

  const [qrModal, setQrModal] = useState(false);


  const hideModalHandler = () => {
    setShowMainModal(false);
  };

  useEffect(() => {
    window.addEventListener("keydown", numericKeyBtnHandler, true);
    return () => {
      window.removeEventListener("keydown", numericKeyBtnHandler);
    };
  }, []);

  const submitCodeHandler = () => {
    const fetch = async () => {
      const res = await userService.getRequestByCode(code);
      console.log(res);
      if (res.status === "NOT_FOUND" || res.length === 0) {
        toast("Invalid code");
        return;
      }
      if (res.error === "invalid_token") {
        toast("Invalid code");
        return;
      }
      if (res.error === "unauthorized") {
        toast("It seems the account disconnect please login again.");
        return;
      }
      setCodeRes(res);
      setCode("");
      setShowMainModal(true);
      // setTodayHistory(await userService.getRequestByCode(code));
    };
    fetch();
  };

  const qrCodeHandler = (scannedCode) => {
    const fetch = async () => {
      const res = await userService.getRequestByCode(scannedCode);
      console.log(res);
      if (res.status === "NOT_FOUND" || res.length === 0) {
        toast("Invalid code");
        return;
      }
      if (res.error === "invalid_token") {
        toast("Invalid code");
        return;
      }
      if (res.error === "unauthorized") {
        toast("It seems your account disconnect. Please login to it again.");
        return;
      }
      setCodeRes(res);
      setShowMainModal(true);
      setQrModal(false);
      // setTodayHistory(await userService.getRequestByCode(code));
    };
    fetch();
  };


  const numericBtnHandler = (e) => {
    
    if (e.target?.value === "clear") {
      setCode("");
      return;
    }

    if (e.target?.value === "delete") {
      setCode(code.substring(0, code.length - 1));
      return;
    }

    setCode((prevState) => {
      return (prevState += +e.target.value);
    });
  };

  const numericKeyBtnHandler = (e) => {
    console.log(e.code);
    if (e.code?.charCodeAt(6) > 48 && e.code?.charCodeAt(6) < 58) {
      setCode((prevState) => {
        return (prevState += +e.code[6]);
      });
    }
    if (e.code === "Backspace") {
      setCode((prevState) => {
        let string = prevState;
        string = string.substring(0, string.length - 1);
        console.log(string);
        return string;
      });
    }
  };

  return (
    <>
      {showMainModal && (
       <EnterCodeModal codeRes={codeRes} hideModalHandler={hideModalHandler} />
      )}

      {/*{qrModal && (*/}
      {/*  <QrCodeModal*/}
      {/*    qrCodeHandler={qrCodeHandler}*/}
      {/*    hideModalHandler={() => setQrModal(false)}*/}
      {/*  />*/}
      {/*)}*/}
      <div className="justify-center justify-around xsm:w-[85vw] w-[43.92386530014641vw] m-auto flex flex-col items-center">

        {/*<div id="codeBar" className="mt-[30px]  items-center mb-[16px]  rounded-[0.25rem] w-[100%] h-[65px] bg-[#d1ecf1]  border-[#bee5eb]">*/}
          <input
              className="mt-[30px]  items-center mb-[16px]  rounded-[0.25rem] w-[60%] h-[70px]  bg-[#e9ecef]  border-[#bee5eb] text-[32px] indent-2"
            onKeyDown={(e) => {
              numericKeyBtnHandler(e);
            }}
            onClick={(e) => e.stopPropagation()}
            contentEditable
           // className="mt-[30px] items-center mb-[16px] w-[70%x] h-[70px] !rounded-[0.3rem] border-[1px] border-black bg-[#e9ecef] text-[32px] indent-2"
            type={"text"}
            disabled
            placeholder={t('insertCode')}
            defaultValue={code}
          ></input>

          {/*<button*/}
          {/*  onClick={() => {*/}
          {/*    setQrModal(true);*/}
          {/*  }}*/}
          {/*  className="w-1/3 ml-4 hover:text-white xsm:py-[0px] py-[6px] xsm:px-[0px] px-[12px] min-w-[90.6px] h-[70px] border-[1px] border-black border-collapse hover:bg-[#343a40] transition-all duration-200 text-[40px] text-[#212529]"*/}
          {/*>*/}
          {/*  Scan*/}
          {/*</button>*/}
        {/*</div>*/}

        <div className="xsm:mt-[30px] mt-[1.5rem] xsm:w-[85vw] w-[43.92386530014641vw] flex flex-wrap border-collapse justify-center">
          <button
            onClick={numericBtnHandler}
            value={"1"}
            className="w-1/3 hover:text-white xsm:py-[0px] py-[6px] xsm:px-[0px] px-[12px] min-w-[90.6px] h-[74px] border-[1px] border-black border-collapse hover:bg-[#343a40] transition-all duration-200 text-[40px] text-[#212529]"
          >
            1
          </button>
          <button
            onClick={numericBtnHandler}
            value={"2"}
            className="w-1/3 hover:text-white xsm:py-[0px] py-[6px] xsm:px-[0px] px-[12px] min-w-[90.6px] h-[74px] border-[1px] border-black border-collapse hover:bg-[#343a40] transition-all duration-200 text-[40px] text-[#212529]"
          >
            2
          </button>
          <button
            onClick={numericBtnHandler}
            value={"3"}
            className="w-1/3 hover:text-white xsm:py-[0px] py-[6px] xsm:px-[0px] px-[12px] min-w-[90.6px] h-[74px] border-[1px] border-black border-collapse hover:bg-[#343a40] transition-all duration-200 text-[40px] text-[#212529]"
          >
            3
          </button>
          <button
            onClick={numericBtnHandler}
            value={"4"}
            className="w-1/3 hover:text-white xsm:py-[0px] py-[6px] xsm:px-[0px] px-[12px] min-w-[90.6px] h-[74px] border-[1px] border-black border-collapse hover:bg-[#343a40] transition-all duration-200 text-[40px] text-[#212529]"
          >
            4
          </button>
          <button
            onClick={numericBtnHandler}
            value={"5"}
            className="w-1/3 hover:text-white xsm:py-[0px] py-[6px] xsm:px-[0px] px-[12px] min-w-[90.6px] h-[74px] border-[1px] border-black border-collapse hover:bg-[#343a40] transition-all duration-200 text-[40px] text-[#212529]"
          >
            5
          </button>
          <button
            onClick={numericBtnHandler}
            value={"6"}
            className="w-1/3 hover:text-white xsm:py-[0px] py-[6px] xsm:px-[0px] px-[12px] min-w-[90.6px] h-[74px] border-[1px] border-black border-collapse hover:bg-[#343a40] transition-all duration-200 text-[40px] text-[#212529]"
          >
            6
          </button>
          <button
            onClick={numericBtnHandler}
            value={"7"}
            className="w-1/3 hover:text-white xsm:py-[0px] py-[6px] xsm:px-[0px] px-[12px] min-w-[90.6px] h-[74px] border-[1px] border-black border-collapse hover:bg-[#343a40] transition-all duration-200 text-[40px] text-[#212529]"
          >
            7
          </button>
          <button
            onClick={numericBtnHandler}
            value={"8"}
            className="w-1/3 hover:text-white xsm:py-[0px] py-[6px] xsm:px-[0px] px-[12px] min-w-[90.6px] h-[74px] border-[1px] border-black border-collapse hover:bg-[#343a40] transition-all duration-200 text-[40px] text-[#212529]"
          >
            8
          </button>
          <button
            onClick={numericBtnHandler}
            value={"9"}
            className="w-1/3 hover:text-white xsm:py-[0px] py-[6px] xsm:px-[0px] px-[12px] min-w-[90.6px] h-[74px] border-[1px] border-black border-collapse hover:bg-[#343a40] transition-all duration-200 text-[40px] text-[#212529]"
          >
            9
          </button>
          <button
            onClick={numericBtnHandler}
            value={"clear"}
            className="w-1/3 hover:text-white xsm:py-[0px] py-[6px] xsm:px-[0px] px-[12px] min-w-[90.6px] h-[74px] border-[1px] border-black border-collapse hover:bg-[#343a40] transition-all duration-200 xsm:text-[24px] text-[40px] text-[#212529] bg-[#DC3545] py-[6px] px-[12px] .text-end "
          >
            {t('delete')}
          </button>
          <button
            onClick={numericBtnHandler}
            value={"delete"}
            className="w-1/3 hover:text-white xsm:py-[0px] py-[6px] xsm:px-[0px] px-[12px] min-w-[90.6px] h-[74px] border-[1px] border-black border-collapse hover:bg-[#343a40] transition-all duration-200 xsm:text-[24px] text-[40px] text-[#212529] .text-end "
          >
            מחק אות
          </button>
          <button
            onClick={numericBtnHandler}
            value={"0"}
            className="w-1/3 hover:text-white xsm:py-[0px] py-[6px] xsm:px-[0px] px-[12px] min-w-[90.6px] h-[74px] border-[1px] border-black border-collapse hover:bg-[#343a40] transition-all duration-200 text-[40px] text-[#212529]"
          >
            0
          </button>
        </div>
        <button
          onClick={submitCodeHandler}
          className="rounded-full mt-[20px] xsm:w-[85vw] w-[60%] h-[50px] bg-[#EBBC33] text-[22px] text-white"
        >
          {t('submit')}
        </button>
      </div>
    </>
  );
}
