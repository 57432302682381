import React from "react";
import BrandInfo from "./Components/Registeration/BrandInfo";
import ClubOffer from "./Components/Registeration/ClubOffer";
import Registeration from "./Components/Registeration/Registeration";
import Login from "./Components/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Components/Home/Home";
import Main from "./Components/Home/Main";
import MonthlyReport from "./Components/Home/MonthlyReport";
import DailyReport from "./Components/Home/DailyReport";
import AddBenefit from "./Components/Home/AddBenefit";
import ManageClubs from "./Components/Home/ManageClubs";
import Protected from "./Components/Protected/Protected";
import { useState, useEffect } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import {
  auth,
  db,
  onMessageListener,
  onForegroundMessage,
} from "./Components/Firebase/firebase";
import Catalog from "./Components/catalog/Catalog";
import AddClub from "./Components/Home/AddClub";
import ManageBenifitz from "./Components/Home/Clubs/ManageBenifitz";
import AddbenifitzToClub from "./Components/Home/Clubs/AddbenifitzToClub";

function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  console.log(show, notification);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  let data = {};

  const getDataFromRegisteration = async (rdata) => {
    data = rdata;
    console.log(data);
  };

  const [benefitArrayFC, setBenefitArrayFC] = useState([]);
  const getDataFromClubOffer = (benefitArray) => {
    setBenefitArrayFC(benefitArray);
  };

  const getDataFromBrandInfo = (bdata) => {
    data.brandInfo = bdata;
    console.log(data);

    toast.dismiss();
    toast.loading("Registering User");
    createUserWithEmailAndPassword(
      auth,
      data.brandInfo.email,
      data.brandInfo.password
    )
      .then(async (response) => {
        try {
          const userdocRef = await setDoc(
            doc(db, "users", response.user.uid),
            data
          );
          console.log(userdocRef);
          toast.dismiss();
          toast.success("User Registeration Success.");
          return <Navigate to={"/"} />;
        } catch (e) {
          console.log(e);
          toast.dismiss();
          toast.error("User Registeration fail.");
        }
      })
      .catch((e) => {
        console.log(e);
        toast.dismiss();
        toast.error("User Registeration fail of user already exists.");
      });
  };

  useEffect(() => {
    onForegroundMessage()
      .then((payload) => {
        console.log("Received foreground message: ", payload);
        const { notification } = payload.notification;
        toast(
          <ToastifyNotification
            title={notification.title}
            body={notification.body}
          />
        );
      })
      .catch((err) =>
        console.log(
          "An error occured while retrieving foreground message. ",
          err
        )
      );
  }, []);

  const ToastifyNotification = ({ title, body }) => (
    <div className="push-notification">
      <h2 className="push-notification-title">{title}</h2>
      <p className="push-notification-text">{body}</p>
    </div>
  );

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="registeration"
            element={
              <Registeration
                getDataFromRegisteration={getDataFromRegisteration}
              />
            }
          />
          <Route
            path="brandinfo"
            element={
              <BrandInfo
                benefitArrayFC={benefitArrayFC}
                getDataFromBrandInfo={getDataFromBrandInfo}
              />
            }
          ></Route>
          <Route
            path="cluboffer"
            element={<ClubOffer getDataFromClubOffer={getDataFromClubOffer} />}
          ></Route>

          <Route path="catalog">
            <Route path=":id" element={<Catalog />} />
          </Route>

          <Route path="/" element={<Protected />}>
            <Route path="home" element={<Home />}>
              <Route path="code" element={<Main />} />
              <Route path="history" element={<DailyReport />} />
              <Route path="dailyHistory" element={<DailyReport/>}/>
              <Route path="monthlyHistory" element={<MonthlyReport/>}/>
              <Route path="Add_Benefit_To_Client" element={<AddBenefit />} />
              <Route path="manage-clubs" element={<ManageClubs />} />
              <Route path="manage-clubs">
              <Route path="add-club" element={<AddClub />} />
                <Route path=":id">
                  <Route path="benifitz" element={<ManageBenifitz />} />
                    <Route path="add" element={<AddbenifitzToClub />} />
                 
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer theme="dark" />
    </>
  );
}

export default App;
