import { useEffect, useState } from "react";
import { userService } from "../../_services";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

export default function MonthlyReport(){
    const { t } = useTranslation();
    let user=localStorage.getItem("user")
    user=JSON.parse(user);

    const [monthlyHistory,setMonthlyHistory]=useState();


    useEffect(()=>{
        const fetch=async()=>{
            setMonthlyHistory(await userService.getHistory({prevDays:90}));
        }
        fetch();
    },[])

    const undoOnclick=async(id,type)=>{
        const result=await userService.undoTransaction(type,id);
    }


    return(
        <div className="flex justify-center flex-col items-center">
            {monthlyHistory?.length===0 &&
                <h2>No history found.</h2>
            }

            {monthlyHistory?.length > 0 && monthlyHistory.map((o,index)=>{
                const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                const currentTime = moment(o.createDate).tz(timezone).format();
                let datetime = currentTime.slice(0, 19).replace('T', ' ');
                datetime=datetime.split(" ")
                let d=datetime[0]
                let hours=datetime[1]

                console.log(o);


                return(
                    <div className="xsm:w-[80vw] w-[50vw] p-[10px] rounded-[0.25rem] border-[1px] py-[30px] mt-[35px] mb-[35px] bg-white flex flex-col gap-[15px]">
                        {o.objectType === "canceled" &&
                            <div className="flex gap-[8px] items-center w-[80%] h-[30px] bg-[#DC3545] m-auto my-0">
                                <h2 className=" text-white font-bold text-center w-[100%]">{t('actionCanceled')}</h2>
                            </div>
                        }

                        <div className="flex gap-[8px]">
                            <label className="font-bold xsm:text-[14px] text-[18px]">{t('date')} </label><h2 className="xsm:text-[14px]">{d+" | "+hours}</h2>
                        </div>

                        {o.objectType !== "canceled" &&
                            <div className="flex gap-[8px]">
                                <label className="font-bold xsm:text-[14px] text-[18px]">{t('action')} </label><h2 className="xsm:text-[14px]">{o.objectType}</h2>
                            </div>
                        }

                        {o.objectType !== "canceled" &&
                            <div className="flex gap-[8px]">
                                <label className="font-bold xsm:text-[14px] text-[18px]">{t('offerDescription')}</label><h2 className="xsm:text-[14px]">{o.offerDescription}</h2>
                            </div>
                        }

                        {o.objectType === "canceled" &&
                            <div className="flex gap-[8px]">
                                <label className="font-bold xsm:text-[14px] text-[18px]">{t('offerDescription')} </label><h2 className="xsm:text-[14px]">{o.benefitOffer.description}</h2>
                            </div>
                        }


                        <div className="flex gap-[8px]">
                            <label className="font-bold xsm:text-[14px] text-[18px]">{t('customerName')} </label><h2 className="xsm:text-[14px]">{o?.customer?.firstName}</h2>
                        </div>

                        <div className="flex gap-[8px]">
                            <label className="font-bold xsm:text-[14px] text-[18px]">{t('phoneNumber')} </label><h2 className="xsm:text-[14px]">{o?.customer?.phoneNo}</h2>
                        </div>

                        <div className="flex gap-[8px]">
                            <label className="font-bold xsm:text-[14px] text-[18px]">{t('pointsReduced')} </label><h2 className="xsm:text-[14px]">{o.pointsReduced}</h2>
                        </div>
                        {(o.objectType == "purchased") &&
                            <div className="flex gap-[8px]">
                                <label className="font-bold xsm:text-[14px] text-[18px]">{t('pointsLeft')} </label><h2 className="xsm:text-[14px]">{o.pointsPurchased-o.pointsReduced}</h2>
                            </div>
                        }
                        {(o.objectType == "usage") &&
                            <div className="flex gap-[8px]">
                                <label className="font-bold xsm:text-[14px] text-[18px]">{t('pointsLeft')} </label><h2 className="xsm:text-[14px]">{o.pointsStatus}</h2>
                            </div>
                        }
                        {(index === 0 && o.objectType !== "canceled" && user.business_id === o.businessId) &&
                            <div className="flex place-content-center gap-[8px]">
                                <button onClick={()=>undoOnclick(o.id,o.objectType)} className="w-[50%] bg-[#DC3545] rounded-[100px] h-[40px] text-white font-bold">{t('undoAction')}</button>
                            </div>
                        }
                    </div>
                )
            })

            }
        </div>
    )
}