
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { getFirestore } from "firebase/firestore";


const firebaseConfigProd = {
  apiKey: "AIzaSyD8vczfc2yhxYa9hS50jOU6CR-usmNhXqQ",
  authDomain: "mybenefits-5e414.firebaseapp.com",
  projectId: "mybenefits-5e414",
  storageBucket: "mybenefits-5e414.appspot.com",
  messagingSenderId: "540905713777",
  appId: "1:540905713777:web:5caecb37fcebcc11282bd0"
};


const firebaseConfigTest = {
  apiKey: "AIzaSyDfJ_gA4mNrxIoNxkk8JNQvhenneMtwq4I",
  authDomain: "warm-tangent-238911.firebaseapp.com",
  databaseURL: "https://warm-tangent-238911.firebaseio.com",
  projectId: "warm-tangent-238911",
  storageBucket: "warm-tangent-238911.appspot.com",
  messagingSenderId: "560527305724",
  appId: "1:560527305724:web:ce1514b9a33b88d5faa079",
  measurementId: "G-61YK5D8XYM"
};

let firebaseConfig = firebaseConfigTest;
let vapidKey = '';

if (process.env.REACT_APP_ENV_TYPE === "prod") {
  firebaseConfig = firebaseConfigProd;
  //TODO
  vapidKey = ''
} else {
  firebaseConfig = firebaseConfigTest;
  vapidKey = 'BDqgQyWME2sY6Z1ZipDjTaY370R_rcSNYEdCyTaFAVN5ljUODFJjtZCG7l92ShCorIR9CA7LGmhs8qwsQ3JWo7s';
}

export const firebase = initializeApp(firebaseConfig);

export const db = getFirestore(firebase);
export const auth = getAuth(firebase);
export const Storage = getStorage(firebase);


// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(firebase);

export const getFirebaseToken = () => {
  return getToken(messaging, {vapidKey: vapidKey}).then((currentToken) => {
    if (currentToken) {
      localStorage.setItem("pushNotificationToken", currentToken );
      console.log('current token for client: ', currentToken);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}


export const onForegroundMessage = () => 
  new Promise((resolve) => 
    onMessage(messaging, (payload) => 
    resolve(payload)));

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage((payload) => {
      resolve(payload);
    });
});
