import "../../Registeration/ClubOffer.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getClubOffer } from "../../../_services/user.service";

export default function ManageBenifitz() {
  const navigate = useNavigate();
  const parmas = useParams();
  const { id } = parmas;
  const [offers, setOffers] = useState([]);
  const [club, setClub] = useState();
  useEffect(() => {
    // const localClub = JSON.parse(localStorage.getItem("clubId"));
    // setClub(localClub);

    getClubBenefitz();
  }, []);

  const getClubBenefitz = async () => {
    try {
      let response = await getClubOffer(id);
      setOffers(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
<div className="flex justify-center flex-col items-center">
      <div className="card_container">
          {offers.length > 0 &&
            offers.map((e, index) => {
              return (
                  <div className="card" key={`card-${index}`}>
                   <div className="detail-section">
                    <p>
                      Type : <span> {e?.type}</span>
                    </p>
                    <div className="flex">
                      <p className="mr-[20px]">
                        Price : <span> {e?.price}</span>
                      </p>
                      <p>
                        Point : <span> {e?.points}</span>
                      </p>
                    </div>
                    <p>
                      Discount Amount : <span> {e?.discountAmount}</span>
                    </p>
                    <p>
                      Description : <span> {e?.description}</span>
                    </p>
                    <p>
                      Long Description : <span> {e?.longDescription}</span>
                    </p>
                  </div>
                    <img className="picture" src={e?.smallPicturlUrl}/>
                  </div>
              );
            })}
        </div>
        <button
          className="rounded-full justify-around mt-[20px] xsm:w-[85vw] w-[43.92386530014641vw] h-[50px] bg-[#EBBC33] text-[22px] text-white"
          onClick={() => {
            navigate(`/home/manage-clubs/${id}/add`);
          }}
        >
          Add Benifitz
        </button>
        </div>
    </>
  );
}
