import React from "react";
import "./Catalog.css";
import { useTranslation } from "react-i18next";
const OfferCard = ({ data, index , club }) => {
  const { t } = useTranslation();
  const onManagePunch = (pointsPurchased) => {
    const newArray = [];

    for (let i = 1; i <= pointsPurchased; i++) {
      if (i > 20) {
        break;
      }

      if (i == pointsPurchased) {
        newArray.push(-11);
      } else {
        newArray.push(i);
      }
    }

    console.log(newArray);
    return newArray;
  };
  return (
    <div className="item" key={`item-offer${index}`}>
      <div className="imageHolder">
        <img src={data?.smallPicturlUrl} alt="smallPicturlUrl" />
      </div>
      <div className="contentItem">
        {/*<div className="contentItemIcons">*/}
        {/*  <img src="/images/material-share.svg" alt="" />*/}
        {/*</div>*/}
        <div className="itemTitle">
          <p>{data?.description}</p>
          <div>
            <span style={{ fontSize: "20px", color: "#4D4D4D" }}>{t('price')} </span>
            <span className="price">{`₪${data?.price}`}</span>
          </div>

          <div className="holes">
            {onManagePunch(data?.points).map((e) => {
              return (
                <div>
                  {/*  */}
                  {e == -11 ? (
                    <div className="holeDiv">
                      <img
                        className="gift-box"
                        src="/images/gift-box.png"
                        alt="gift-box.png"
                      />
                    </div>
                  ) : (
                    <div className="hole">
                      <span>{e}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="footerItem">
          <div>
            <span>{data?.longDescription}</span>

            <span>{data?.usageExpirationPeriodText}</span>
          </div>
          <div className="button-holder">
            <div className="item-logo">
              <img src="/images/logo.svg" alt="" />
            </div>
            <a href={club?.deepLink} className="buttonToApp">לקנייה באפליקציה</a>
          </div>
        </div>
      </div>
    </div>
  );
};


export default OfferCard;
