import { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { auth } from "../Firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { userService } from "../../_services";
import "./Navbar.css";


const Navbar = () => {
    const navigate= useNavigate();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const [user,setUser]= useState();

    useEffect(()=>{
        const fetch=async()=>{
            const res=await userService.getInfo();
            console.log(res)
            setUser(res);
        }
        const updatePushToken=async()=>{
            const res=await userService.updatePushToken();
            console.log(res)
        }

        fetch();
        updatePushToken();
    },[])

    const logoutHandler=()=>{
        localStorage.removeItem("user");
        auth.signOut();
        return navigate("/")
    }

    const toggleDrawer = (e) => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="w-[calc(100vw - 100%)] h-[100vh] bg-[#00b9bf] gradientBackground overflow-y-auto">
            <header className="navbar w-[calc(100vw - 100%)] min-h-[80px] bg-zinc-800 xsm:w-[100%] w-[79.06295754026354vw] m-auto ">
                <div className="navbar-container flex flex-row justify-between items-center h-[100%]">
                    <div className="menu-icon" onClick={toggleDrawer}>
                        &#9776;
                    </div>
                    <div className="logo">{t('welcome',{username: user?.name})}</div>
                    <nav className="nav-links flex gap-[20px] ml-[20px]">
                        <NavLink to="/home/code" className="nav-link" activeClassName="active">
                            {t('home')}
                        </NavLink>
                        <NavLink to="/home/dailyHistory" className="nav-link" activeClassName="active">
                            {t('daily')}
                        </NavLink>
                        <NavLink to="/home/monthlyHistory" className="nav-link" activeClassName="active">
                            {t('monthly')}
                        </NavLink>
                        <NavLink to="/home/manage-clubs" className="nav-link" activeClassName="active">
                            {t('manageClub')}
                        </NavLink>
                        <div className="logout" onClick={() => {
                            logoutHandler();
                        }}>
                            {t('logout')}
                        </div>
                    </nav>

                </div>
                <div className={`drawer ${isOpen ? "open" : ""}`}>
                    <NavLink to="/home/code" className="drawer-link" onClick={toggleDrawer}>
                        {t('home')}
                    </NavLink>
                    <NavLink to="/home/DailyHistory" className="drawer-link" onClick={toggleDrawer}>
                        {t('daily')}
                    </NavLink>
                    <NavLink to="/home/MonthlyHistory" className="drawer-link" onClick={toggleDrawer}>
                        {t('monthly')}
                    </NavLink>
                    <NavLink to="/home/manage-clubs" className="drawer-link" onClick={toggleDrawer}>
                        {t('manageClub')}
                    </NavLink>
                    <div className="logout" onClick={() => {
                        toggleDrawer();
                        logoutHandler();
                    }}>
                        {t('logout')}
                    </div>
                </div>
            </header>
            <main className="w-[calc(100vw - 100%)] h-[calc(100%)] ">
                <Outlet />
            </main>
        </div>
    );
};
export default Navbar;


//export default function Home(){
//   const navigate=useNavigate();
//    let style1={
//    maxHeight:500,
//    opacity:1,
//    backgroun:"aqua"
//    }
//
//const [navStyle,setNavStyle]=useState(false)
//const showMobileNav=()=>{
// setNavStyle(!navStyle);
//}
//
// return(
//       <div className="w-[calc(100vw - 100%)] h-[100vh] bg-[#00b9bf] gradientBackground overflow-y-auto">
//           <header className="navbar w-[calc(100vw - 100%)] min-h-[80px] bg-zinc-800 xsm:w-[100%] w-[79.06295754026354vw] m-auto">
//               <div className="nav-container flex flex-col justify-between items-center h-[100%]">
//                    <div className="logo">{t('welcome',{username: user?.name})}</div>
//                   <div className="menu-icon" onClick={showMobileNav}>
//                       &#9776;
//                   </div>
//                    <div className="w-[100%] flex justify-between items-center min-h-[80px]">
//                       <nav className="nav-links flex gap-[20px] ml-[20px]">
//
//                               {/*<div onClick={showMobileNav} className="cursor-pointer hidden xsm:flex flex-col gap-[5px] shadow-[0_0px_1px_rgba(255,255,255,0.3)] rounded-[10px] p-[10px]">*/}
//                               {/*    <div className="w-[30px] h-[2px] rounded-lg bg-[#ffffff80]"></div>*/}
//                               {/*    <div className="w-[30px] h-[2px] rounded-lg bg-[#ffffff80]"></div>*/}
//                               {/*    <div className="w-[30px] h-[2px] rounded-lg bg-[#ffffff80]"></div>*/}
//                               {/*</div>*/}
//                               <h3  className="text-[#ffffff80] hover:opacity-[0.5] cursor-pointer xsm:hidden" style={{ color:'aqua'}} > { t ('welcome',{username: user?.name})}</h3>
//                               <NavLink className="text-[#ffffff80] hover:opacity-[0.5] xsm:hidden" to={"/home/code"}>{t('home')}  </NavLink>
//                               <NavLink className="text-[#ffffff80] hover:opacity-[0.5] xsm:hidden" to={"/home/dailyHistory"} >{ t('daily')}</NavLink>
//                               <NavLink className="text-[#ffffff80] hover:opacity-[0.5] xsm:hidden" to={"/home/monthlyHistory"} >{t('monthly')}</NavLink>
//                               <NavLink className="text-[#ffffff80] hover:opacity-[0.5] xsm:hidden" to={"/home/manage-clubs"} >{t('manageClub')}</NavLink>
//                               {/*<NavLink className="text-[#ffffff80] hidden xsm:flex text-center hover:opacity-[0.5]" to={"/home/Add_Benefit_To_Client"} >הוספת הטבה ללקוח</NavLink>*/}
//                               <h3  onClick={logoutHandler} className="xsm:hidden text-[#ffffff80] mr-[20px] cursor-pointer hover:opacity-[0.5]" >{t('logout')}</h3>
//                       </nav>
//                   </div>
//                    {/* */}
//                   {/*flex flex-col gap-[10px] mb-[10px] animation*/}
//                    <div style={navStyle ? style1 : {}} className='drawer ${navStyle ? "open" : ""}'>
//                       <h3 className="text-[#ffffff80] hover:opacity-[0.5] cursor-pointer xsm:flex hidden mr-[1%]">  { t ('welcome',{username: user?.name})}</h3>
//                       <NavLink className="text-[#ffffff80] hidden xsm:flex text-center hover:opacity-[0.5]" to={"/home/code"} onClick={showMobileNav}>{t('home')} </NavLink>
//                       <NavLink className="text-[#ffffff80] hidden xsm:flex text-center hover:opacity-[0.5]" to={"/home/dailyHistory"} onClick={showMobileNav}>{t('daily')}</NavLink>
//                       <NavLink className="text-[#ffffff80] hidden xsm:flex text-center hover:opacity-[0.5]" to={"/home/monthlyHistory"} onClick={showMobileNav}>{t('monthly')}</NavLink>
//                       <NavLink className="text-[#ffffff80] hidden xsm:flex text-center hover:opacity-[0.5]"to={"/home/manage-clubs"} onClick={showMobileNav}>{t('manageClub')}</NavLink>
//                       {/*<NavLink className="text-[#ffffff80] hidden xsm:flex text-center hover:opacity-[0.5]" to={"/home/Add_Benefit_To_Client"} onClick={showMobileNav}>הוסף הטבה ללקוח</NavLink>*/}
//                       <h3 onClick={() => { showMobileNav(); logoutHandler();}}  className="hidden xsm:flex text-center text-[#ffffff80] mr-[20px] cursor-pointer hover:opacity-[0.5]">{t('logout')}</h3>
//
//                   </div>
//               </div>
//           </header>
//           <main className="w-[calc(100vw - 100%)] h-[calc(100%)] ">
//               <Outlet />
//           </main>
//
//       </div>
//   )
//}