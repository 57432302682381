import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ManageClubs.css";
import QRCode from "qrcode.react";
import {userService} from "../../_services";

export default function ManageClubs() {
  const navigate = useNavigate();
  const [allClubs, setAllClubs] = useState();
  let qrCodeRef = React.createRef();


  useEffect(()=>{
    const fetch=async()=>{
      // return a list of clubs
      setAllClubs(await userService.getAllClubsOfBusiness());
    }
    fetch();
  },[])

  const [inputText, setInputText] = useState("");
  const [qrCodeText, setQRCodeText] = useState("");

  // generate QR code
  const generateQRCode = () => {
    setQRCodeText(inputText);
  };

  // download QR code
  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL);
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return(
      <div className="flex justify-center flex-col items-center">
        {allClubs?.length===0 &&
            <h2>No clubs were found.</h2>
        }

        {allClubs?.length > 0 && allClubs.map((club,index)=>{
          return (
              <>
                <div className="card_container ">
                  <div
                      className="card"
                      style={{ cursor: "pointer " }}
                      onClick={() => {
                        navigate(`/home/manage-clubs/${club?.id}/benifitz`);
                      }}
                  >
                    <div className="detail-section">
                      <h3>{club?.name ?? ""}</h3>
                      <p>
                        Description : <span> {club?.description}</span>
                      </p>
                      <p>
                        Email : <span> {club?.email}</span>
                      </p>
                      <p>
                        Phone Number : <span> +{club?.phoneNum}</span>
                      </p>
                      <p>
                        Type : <span> {club?.type}</span>
                      </p>
                      <p>
                        Type : <span> {club?.id}</span>
                      </p>
                    </div>
                    <div className="picture " >
                      <QRCode id="qrCodeEl" value={club?.deepLink} />
                      <button
                          className="rounded-full  justify-around mt-[20px] xsm:w-[85vw] w-[60%] h-[50px] bg-[#EBBC33] text-[20px] text-white"
                          onClick={(e) => {
                            e.stopPropagation();
                            downloadQRCode();
                          }}
                      >
                        Download QR
                      </button>
                    </div>
                  </div>
                </div>
              </>
          );
        })
        }
        <button
            className="rounded-full mt-[20px] justify-around xsm:w-[85vw] w-[30%] h-[50px] bg-[#EBBC33] text-[22px] text-white"
            onClick={() => {
              navigate("/home/manage-clubs/add-club");
            }}
        >
          Add Club
        </button>
      </div>
  )

}
